"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { signOut, useSession } from "next-auth/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import UserSidebar from "@/app/(main)/_user_components/user-sidebar";
import Breadcrumb from "@/components/breadcrumb";
import BurgerMenu from "@/components/global/burger-menu";
import Icon from "@/components/icon";
import ProfilePicture from "@/components/user/profile-picture";
import { breadcrumbContext } from "@/context/breadcrumb-context";
import { isBurgerActiveContext } from "@/context/is-burger-active";
import { MobileContext } from "@/context/mobile-provider";
import { sidebarContext } from "@/context/sidebar-context";
import useMobile from "@/hooks/use-mobile";
import { HeaderProps } from "@/TS/Interfaces/global_interfaces";
const headerArr = [{
  label: "Concept des clubs",
  href: "/concept-des-clubs"
}, {
  label: "Rejoindre un club",
  href: "/rejoindre-un-club"
}, {
  label: "Créer un club",
  href: "/creer-un-club"
}, {
  label: "Témoignages",
  href: "/temoignages"
}, {
  label: "Dynabuy",
  href: "/dynabuy"
}];
export default function Header(props: HeaderProps) {
  const [value, setValue] = useContext(breadcrumbContext);
  const [isActive, setIsActive] = useContext(isBurgerActiveContext);
  const headerRef = useRef<HTMLHeadElement>(null);
  const [user, setUser] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUserConnected, setIsUserConnected] = useState(null);
  const isMobile = useMobile();
  const {
    data: session,
    status,
    update
  } = useSession();
  const {
    val,
    setVal,
    isHide,
    setIsHide
  } = useContext(sidebarContext);
  useEffect(() => {
    if (session) {
      setIsUserConnected(true);
      setUser(session.user);
    } else {
      setIsUserConnected(false);
      setUser(null);
    }
  }, [session]);
  useEffect(() => {
    if (session && session?.error === "RefreshTokenError") {
      signOut({
        callbackUrl: "/connexion"
      });
    }
  }, [session]);
  const handleSubmit = e => {
    e.preventDefault();
    const value = inputRef.current.value;
    router.push("/clubs?search=" + value);
  };
  const path = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const {
    isPublic,
    setIsPublic
  } = useContext(MobileContext);
  return <div className={"sticky border-b border-separator-grey top-0 z-[1000]"} data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <header ref={headerRef} id={"header"} className={"flex flex-col bg-white w-full z-50 items-center py-2"}>
        <div className="flex justify-between items-center px-2 md:px-6 w-full">
          {/*Item1*/}
          {isMobile && isPublic ? <div className={"w-24 px-4"}>
              <BurgerMenu></BurgerMenu>
            </div> : null}

          {/*Item2*/}
          <Link href={"/"} className={"2xl:ml-16"} onClick={() => setIsActive(false)} data-sentry-element="Link" data-sentry-source-file="header.tsx">
            <Image className={"main-logo"} src={"/img/Dynabuy-logo1.svg"} alt={"Dynabuy / Clubs d'affaires"} width={320} height={75} data-sentry-element="Image" data-sentry-source-file="header.tsx" />
          </Link>
          {/*Item3*/}
          <div className={"max-w-[50rem] w-full hidden lg:flex py-6 items-center justify-between m-4"}>
            {headerArr.map(({
            label,
            href
          }) => {
            return <Link key={label} className={`font-bold text-lg hover:text-primary ${path === href ? "text-primary" : ""}`} href={href}>
                  {label}
                </Link>;
          })}
          </div>
          {/*Item4*/}
          <div className={"flex"}>
            <Link href={"/clubs"} className={"flex items-center mx-2 md:mx-4"} data-sentry-element="Link" data-sentry-source-file="header.tsx">
              <button type={"submit"} className={"group xl:p-1 xl:py-2.5 p-2 text-white h-fit  bg-primary hover:bg-red-button aspect-square xl:aspect-auto rounded-full xl:px-2 text-nowrap"}>
                <Icon icon={"search"} size={25} className={"mx-1 text-white xl:!w-8 xl:!h-8"} data-sentry-element="Icon" data-sentry-source-file="header.tsx"></Icon>
                <span className={"hidden xl:inline-block pr-2"}>
                  Rechercher un club
                </span>
              </button>
            </Link>
            {user && <div className={"flex mr-0 xl:mr-16 items-center hover:cursor-pointer"} onClick={() => {
            setVal(<UserSidebar />);
          }}>
                <ProfilePicture mobileSize={40} size={52} src={user.image} firstName={user.firstName} lastName={user.lastName} />
                <div className={"hidden md:block font-bold md:ml-2"}>
                  {user.firstName} <br />
                  {user.lastName}
                </div>
              </div>}
            {!session ? <div className={"flex justify-end"}>
                {!isUserConnected && session !== null && "Loading"}

                <Link href="/connexion" className={"2xl:mr-16 aspect-square flex justify-center items-center xl:aspect-auto rounded-full xl:bg-secondary xl:text-white hover:opacity-75 md:px-2 text-nowrap md:h-full"}>
                  <Icon icon={"user"} className={"mx-1 !w-10 !h-10 xl:!w-8 xl:!h-8"}></Icon>{" "}
                  <span className={"hidden xl:inline-block text-nowrap"}>
                    Je me connecte
                  </span>
                </Link>
              </div> : null}
          </div>
        </div>
      </header>

      {!isActive && isPublic && !isMobile && path !== "/" && <Breadcrumb crumb={value} className={"w-full relative  bottom-0 "}></Breadcrumb>}
    </div>;
}